<template>
  <div class="incomeCenter">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">我要赚钱</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="balanceCard">
        <div class="title">收益余额</div>
        <div class="balanceInfo">
          <div class="balance">
            <div class="goldBox">
              <svg-icon class="goldIcon" iconClass="gold"></svg-icon>
              {{ walletBalance }}
            </div>
            <router-link tag="div" to="/withdraw" class="withdrawBtn">立即提现</router-link>
          </div>
          <div class="withdrawalBalance">可提现余额：{{ (walletBalance * 10) / 100 }}元</div>
        </div>
      </div>
      <div class="marqueeBox">
        <van-notice-bar color="rgb(102,102,102)" background="rgb(243,244,245)" left-icon="volume-o" :scrollable="false">
          <template slot="left-icon">
            <svg-icon class="withdrawal" iconClass="withdrawal" />
          </template>
          <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false" :touchable="false">
            <van-swipe-item v-for="(item, index) in list" :key="index">
              {{ item.content }}
            </van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>
      <div class="incomeData">
        <div class="flex">
          <div class="incomeType">推广收益:</div>
          <div class="incomeNum">{{ walletInfo.proxyIncome || 0 }}</div>
        </div>
        <div class="flex">
          <div class="incomeType">投稿收益:</div>
          <div class="incomeNum">{{ walletInfo.vidIncome || 0 }}</div>
        </div>
      </div>

      <div class="linkBox">
        <router-link tag="div" to="/billDetails" class="linkItem">
          <svg-icon class="linkIcon" iconClass="incomeDetail" />
          <div class="linkText">账单明细</div>
        </router-link>
        <router-link tag="div" to="/promoRecord" class="linkItem">
          <svg-icon class="linkIcon" iconClass="promotionRecord" />
          <div class="linkText">推广记录</div>
        </router-link>
        <router-link tag="div" to="/sharePromote" class="linkItem">
          <svg-icon class="linkIcon" iconClass="promoteShare" />
          <div class="linkText">推广分享</div>
        </router-link>
      </div>

      <div class="promotionRules">
        <div class="title">推广返利</div>
        <div class="tableTitle">
          <div>累计充值</div>
          <div>返利百分比</div>
        </div>
        <div class="tableDataBox">
          <div class="tableData">
            <div>2千以下</div>
            <div>返利50%</div>
          </div>
          <div class="tableData">
            <div>2千～1万</div>
            <div>返利60%</div>
          </div>
          <div class="tableData">
            <div>1万～3万</div>
            <div>返利65%</div>
          </div>
          <div class="tableData">
            <div>3万以上</div>
            <div>返利70%</div>
          </div>
        </div>
      </div>

      <div class="process">
        <div class="processTitle">推广流程</div>
      </div>
      <div class="stepBox">
        <div class="stepContent">
          <div class="stepContent-box1">
            <div class="stepContent-title">
              <div class="dot"></div>
              第一步：点击“
              <span>分享推广</span>
              ”
            </div>
            <div class="stepContent-details">将 ‘二维码’ 分享给其他人或其他渠道，对方扫码下载APP并登录生成了新账号，则视作推广成功。</div>
          </div>
          <div class="stepContent-box2">
            <div class="stepContent-title">
              <div class="dot"></div>
              第二步：对方“充值”
            </div>
            <div class="stepContent-details">
              推广的用户充值，则充值金额按照 “推广级别” 的比例返利带你，若有什么疑问请联系“
              <span @click="$router.push('/kefu')">在线客服</span>
              ”
            </div>
          </div>
          <div class="stepContent-title">
            <div class="dot"></div>
            第三步：
            <span>“收益提现”</span>
            或
            <span>“兑换会员”</span>
          </div>
          <div class="contentBox stepContent-details">
            <div class="contentItem">收益可提现或兑换会员，月入万元不是梦！</div>
            <div class="contentItem">
              <p>收益提现</p>
              <i></i>
              <p>银行卡/支付宝</p>
            </div>
            <div class="contentItem">
              <p>收益提现</p>
              <i></i>
              <p>银行卡/支付宝</p>
            </div>
            <div class="contentItem">
              <p>兑换会员</p>
              <i></i>
              <p>无限观影</p>
            </div>
            <div class="contentItem-example">举例：推广的用户充值100元，返利60～75元，业绩越高比例越高。</div>
          </div>
          <div class="bottomTip">
            一看即会，任何人都可以参与，边吃瓜边赚钱
            <br />
            终身享受利润分红，任何疑问请联系
            <span @click="$router.push('/kefu')">在线客服</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWithdrawalMarquee } from '@/api/movies/index.js';
export default {
  nema: 'IncomeCenter',
  data() {
    return {
      walletInfo: {},
      walletBalance: 0,
      list: [],
    };
  },
  created() {
    this.getWalletInfo();
    this.getMarqueeList();
  },
  methods: {
    async getWalletInfo() {
      this.$store.commit('app/SET_LOADING', true);
      try {
        await this.$store.dispatch('user/getWallet');
        this.$store.commit('app/SET_LOADING', false);
        this.walletInfo = this.$store.getters.walletInfo;
        this.walletBalance = (this.walletInfo.income * 100 + this.walletInfo.incomePot * 100) / 100;
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
      }
    },
    async getMarqueeList() {
      let res = await this.$Api(getWithdrawalMarquee);
      if (res.code == 200) {
        this.list = res.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.incomeCenter {
  height: 100%;

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 11px 12px;
    background-color: #fff;
    .balanceCard {
      height: 119px;
      border-radius: 4px;
      box-sizing: border-box;
      background: url('./../../../assets/png/incomeBg.png') no-repeat;
      background-size: 100% 100%;
      padding: 11px 16px;
      .title {
        font-size: 16px;
        color: #fff;
      }
      .balanceInfo {
        margin-top: 12px;
        .balance {
          width: 100%;
          font-size: 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .goldBox {
            width: 124px;
            height: 34px;
            color: #fff;
            font-size: 20px;
            background-image: linear-gradient(
              to right,
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 0.22),
              rgba(255, 255, 255, 0.32),
              rgba(255, 255, 255, 0.22),
              rgba(255, 255, 255, 0)
            );
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .goldIcon {
              height: 25px;
              width: 25px;
              margin-left: 10px;
            }
          }

          .withdrawBtn {
            width: 96px;
            height: 34px;
            line-height: 34px;
            font-size: 16px;
            border-radius: 4px;
            text-align: center;
            background-color: #fff;
            color: rgb(255, 103, 143);
          }
        }

        .withdrawalBalance {
          font-size: 14px;
          color: rgb(255, 230, 195);
          margin-top: 6px;
        }

        .details {
          width: 84px;
          height: 28px;
          line-height: 28px;
          border-radius: 28px;
          font-size: 12px;
          margin-top: 4.5px;
          text-align: center;
          color: #ffd09d;
          background: #383838;
        }
      }
    }
    .marqueeBox {
      margin-top: 10px;
      .withdrawal {
        height: 26px;
        width: 44px;
      }
      .notice-swipe {
        height: 40px;
        line-height: 40px;
        font-size: 13px !important;
      }
    }
  }

  .incomeData {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    margin-top: 18px;

    .incomeType {
      color: rgb(153, 153, 153);
      margin-right: 10px;
    }

    .incomeNum {
      color: rgb(51, 51, 51);
    }
  }

  .linkBox {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 18px 0 22px;

    .linkItem {
      display: flex;
      flex-direction: column;
      align-items: center;

      .linkIcon {
        width: 46px;
        height: 46px;
      }

      .linkText {
        font-size: 14px;
        margin-top: 6px;
        color: rgb(102, 102, 102);
      }
    }
  }

  .promotionRules {
    padding: 0px 0 19px;
    box-sizing: border-box;
    border-radius: 4px;

    .title {
      font-size: 18px;
      text-align: center;
      color: rgb(255, 103, 143);
    }

    .tableTitle {
      font-size: 16px;
      margin: 12px 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .tableDataBox {
      background-color: rgb(246, 246, 246);
      border: 4px;
      .tableData {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        opacity: 0.6;
        padding: 15px 0;
        border-bottom: 1px solid rgb(224, 230, 234);
      }
      .tableData:last-of-type {
        border-bottom: unset;
      }
    }
  }

  .process {
    margin: 0 0 10px;
    .processTitle {
      font-size: 18px;
      text-align: center;
      color: rgb(255, 103, 143);
    }
  }

  .stepBox {
    margin-bottom: 30px;

    .stepContent {
      margin-left: 18px;

      span {
        color: rgb(255, 103, 143);
      }

      .stepContent-box1 {
        height: 120px;
        overflow: hidden;
      }

      .stepContent-box2 {
        height: 2.61rem;
        overflow: hidden;
      }
    }

    .stepContent {
      margin-left: 18px;

      .stepContent-title {
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        margin-bottom: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .dot {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: rgb(255, 148, 105);
          margin-right: 10px;
        }
      }

      .stepContent-box1 {
        height: 94px;
        overflow: hidden;
      }

      .stepContent-box2 {
        height: 90px;
        overflow: hidden;
      }

      .stepContent-details {
        font-size: 14px;
        color: rgb(102, 102, 102);
        margin-left: 20px;
      }

      .contentItem {
        margin-bottom: 6px;
        height: 16px;
        font-size: 14px;
        color: rgb(102, 102, 102);
        display: flex;
        align-items: center;

        i {
          background: url('../../../assets/png/red-arrow.png');
          background-size: 100% 100%;
          width: 24px;
          height: 14px;
          margin: 0 5px;
        }
      }

      .contentItem-example {
        font-size: 12px;
        color: rgb(153, 153, 153);
        margin-bottom: 20px;
      }
      .bottomTip {
        font-size: 16px;
        color: rgb(102, 102, 102);
        // margin-left: 10px;
      }
    }
  }
}
</style>
